
import { defineComponent } from "vue";
import { FilterMatchMode } from 'primevue/api';

import Card from "primevue/card";
import Button from "primevue/button";
import ProgressSpinner from "primevue/progressspinner";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import Calendar from "primevue/calendar";
import InputNumber from "primevue/inputnumber";

import Utils from "@/utility/utils";

export default defineComponent({
  name: "PayableRegisterHistoryTab",
  components: {
    Card,
    Button,
    ProgressSpinner,
    DataTable,
    Column,
    InputText,
    Calendar,
    InputNumber,
  },
  data() {
    return {
      isLoading: false,
      firstLoadComplete: false,
      itemsPerPage: 15,
      expandedRows: [] as any[],
      isAllExpanded: false,
      filters: {
        apreg_id: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      history_items: [
        { 
          reg_aging_name: 'Accruals',
          date: '06-24-97',
          amount: '1000.00',
          items: [
            {
              "apreg_id": "181698",
              "amount": "1000.00",
              "account": "000-0602",
              "date": "03/29/2022",
              "co_code": "1",
              "procedure": "RECEIPTS.P1",
              "record": "7019-3",
              "stamp_date": "03/29/2022",
              "stamp_id": "CATHIEM"
            },
            {
              "apreg_id": "181699",
              "amount": "-1000.00",
              "account": "000-0701",
              "date": "03/30/2021",
              "co_code": "1",
              "procedure": "RECEIPTS.P1",
              "record": "7019-3",
              "stamp_date": "03/30/2021",
              "stamp_id": "CATHIEM"
            }
          ]
        },
        { 
          reg_aging_name: 'Aging',
          date: '12-20-23',
          amount: '2000.00',
          items: [
            {
              "apreg_id": "158270",
              "amount": "2000.00",
              "account": "000-0602",
              "date": "03/30/2022",
              "co_code": "1",
              "procedure": "RECEIPTS.P1",
              "record": "7019-3",
              "stamp_date": "03/28/2022",
              "stamp_id": "CATHIEM"
            },
            {
              "apreg_id": "158271",
              "amount": "-650.00",
              "account": "000-0701",
              "date": "03/30/2022",
              "co_code": "1",
              "procedure": "RECEIPTS.P1",
              "record": "7019-3",
              "stamp_date": "03/30/2022",
              "stamp_id": "CATHIEM"
            },
            {
              "apreg_id": "158272",
              "amount": "-1350.00",
              "account": "000-0701",
              "date": "03/31/2022",
              "co_code": "1",
              "procedure": "RECEIPTS.P1",
              "record": "7019-3",
              "stamp_date": "03/30/2022",
              "stamp_id": "CATHIEM"
            }
          ]
        }
      ] as any[],
    };
  },
  created() {
    // Simulate loading
    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false;
    }, 1000)

    this.history_items.forEach((item: any) => {
      item.items?.forEach((subItem: any) => {
        subItem.sortableDate = new Date(subItem.date);
      });
    })
  },
  methods: {
    toggleExpandAll() {
      if (this.isAllExpanded) {
        this.collapseAll();
      } else {
        this.expandAll();
      }
      this.isAllExpanded = !this.isAllExpanded;
    },
    expandAll() {
      this.expandedRows = JSON.parse(JSON.stringify(this.history_items)); 
    },
    collapseAll() {
      this.expandedRows = [] as any[];
    },
    getExpandAllBtnLabel() {
      if (this.expandedRows.length === this.history_items.length) {
        this.isAllExpanded = true;
        return "Collapse All";
      }
      return this.isAllExpanded ? "Collapse All" : "Expand All";
    },
    getExpandAllBtnIcon() {
      if (this.expandedRows.length === this.history_items.length)
        return "pi pi-minus";
      return this.isAllExpanded ? "pi pi-minus" : "pi pi-plus";
    },
    formatPrice(amt: string){
      if (!amt || parseInt(amt) === 0) {
        return '';
      } else {
        return Utils.formatPrice(amt);
      }
    },
  },
  watch: {
    isLoading(val) {
      if (!val && !this.firstLoadComplete && this.history_items.length > 0) {
        setTimeout(() => {
          this.toggleExpandAll();
          this.firstLoadComplete = true;
        }, 0)
      }
    },
  },
});
